import Vue from 'vue'
import App from './App.vue'
import config from './config';
import {i18n} from '@/plugins/i18n';
import router from '@/router';
import '@/helpers/import.ui.components';

import LocalStore from '@/helpers/LocalStore';

import GeneralService from './services/general.service';
const companies = new GeneralService();

const env = config.stage;

Vue.config.productionTip = config[env] !== "development";

Vue.prototype.$ws_server = function () {
    return config[env].ws_server;
};

Vue.prototype.$s3_url = function () {
    return config[env].s3_url;
};

Vue.prototype.$getCompany = function () {
    return LocalStore.getCompany();
};

companies.info()
    .then(res => {
        if (res && !res.error) {

            LocalStore.setCompany(res.data);

            new Vue({
                router,
                i18n,
                render: h => h(App)
            }).$mount('#ez_leads_car_details');
        } else {
            // eslint-disable-next-line no-console
            console.error('Licensing Error');
        }
    })
    .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
    });

<template v-if="type">
  <section class="widget">
    <div class="widget__appointment" v-if="type === 'appointment'">
      <div id="ez_leads_appointment"/>
    </div>

    <div class="widget__appraisal" v-else-if="type === 'appraisal'">
      <div id="ez_leads_trade-in"/>
    </div>

    <div class="widget__contact" v-else-if="type === 'contact'">
      <div id="ez_leads_contact"/>
    </div>

    <div class="widget__financing" v-else-if="type === 'financing'">
      <div id="ez_leads_financing"/>
    </div>

    <div class="widget__testdrive" v-else-if="type === 'testdrive'">
      <div id="ez_leads_testdrive"/>
    </div>

    <script async type="application/javascript" :src="widgetSrc"/>
  </section>
</template>

<script>
export default {
  name: 'Widget',
  props: {
    type: {
      type: String,
      enum: ['appointment', 'appraisal', 'contact', 'financing', 'testdrive', 'livechat'],
      default: null
    },
  },
  computed: {
    widgetSrc() {
      return `${this.$ws_server()}/${this.type}.js`;
    }
  }
}
</script>

<style scoped lang="scss">
.widget {
}
</style>
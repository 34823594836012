import hours from "@/helpers/hours";
import dh from "@/helpers/date.helper";

import Widget from "@/components/Widget";
import InventoryService from "@/services/inventory.service";

const service = new InventoryService();

export default {
    name: "CarDetails",

    components: {
        Widget
    },

    data() {
        return {
            car: null,
            similars: []
        }
    },

    async mounted() {
        await Promise.all([this.get(), this.getSimilars()]);
    },

    computed: {
        companyName() {
            return this.$getCompany().Name;
        },

        companyDomain() {
            return this.$getCompany().Domain[0].Domain
        },

        companyLogo() {
            return `${this.$ws_server()}/api/files/download/${this.$getCompany().Logo}`;
        },

        location() {
            const idx = this.$getCompany().Locations.findIndex(loc => loc._id === this.car.Location);
            return idx > -1 ? this.$getCompany().Locations[idx] : null;
        },
    },

    methods: {
        async get() {
            const vin = this.$route.params.vin;
            if (!vin) return;

            const res = await service.getByVin(vin);
            if (res && !res.error) {
                this.car = res.data;
            }
        },

        async getSimilars() {
            const vin = this.$route.params.vin;
            if (!vin) return;

            const res = await service.getSimilars(vin);
            if (res && !res.error) {
                this.similars = res.data.similars;
            }
        },

        locationHours() {
            const rawHours = this.location.Hours,
                today = dh.formatDate(new Date(), "ddd");

            const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
            const sortedDays = days.slice(days.indexOf(today)).concat(days.slice(0, days.indexOf(today)));

            return sortedDays.map(day => {
                return {
                    day: day,
                    isOpen: rawHours[day].Enabled,
                    start: hours[rawHours[day].Start].text,
                    end: hours[rawHours[day].End].text,
                }
            });
        },
    }
};

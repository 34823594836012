export default [
    {index: 0, text: '12:00 A.M', value: "00:00"},
    {index: 1, text: '12:30 A.M', value: "00:30"},
    {index: 2, text: '1:00 A.M', value: "01:00"},
    {index: 3, text: '1:30 A.M', value: "01:30"},
    {index: 4, text: '2:00 A.M', value: "02:00"},
    {index: 5, text: '2:30 A.M', value: "02:30"},
    {index: 6, text: '3:00 A.M', value: "03:00"},
    {index: 7, text: '3:30 A.M', value: "03:30"},
    {index: 8, text: '4:00 A.M', value: "04:00"},
    {index: 9, text: '4:30 A.M', value: "04:30"},
    {index: 10, text: '5:00 A.M', value: "05:00"},
    {index: 11, text: '5:30 A.M', value: "05:30"},
    {index: 12, text: '6:00 A.M', value: "06:00"},
    {index: 13, text: '6:30 A.M', value: "06:30"},
    {index: 14, text: '7:00 A.M', value: "07:00"},
    {index: 15, text: '7:30 A.M', value: "07:30"},
    {index: 16, text: '8:00 A.M', value: "08:00"},
    {index: 17, text: '8:30 A.M', value: "08:30"},
    {index: 18, text: '9:00 A.M', value: "09:00"},
    {index: 19, text: '9:30 A.M', value: "09:30"},
    {index: 20, text: '10:00 A.M', value: "10:00"},
    {index: 21, text: '10:30 A.M', value: "10:30"},
    {index: 22, text: '11:00 A.M', value: "11:00"},
    {index: 23, text: '11:30 A.M', value: "11:30"},
    {index: 24, text: '12:00 P.M', value: "12:00"},
    {index: 25, text: '12:30 P.M', value: "12:30"},
    {index: 26, text: '1:00 P.M', value: "13:00"},
    {index: 27, text: '1:30 P.M', value: "13:30"},
    {index: 28, text: '2:00 P.M', value: "14:00"},
    {index: 29, text: '2:30 P.M', value: "14:30"},
    {index: 30, text: '3:00 P.M', value: "15:00"},
    {index: 31, text: '3:30 P.M', value: "15:30"},
    {index: 32, text: '4:00 P.M', value: "16:00"},
    {index: 33, text: '4:30 P.M', value: "16:30"},
    {index: 34, text: '5:00 P.M', value: "17:00"},
    {index: 35, text: '5:30 P.M', value: "17:30"},
    {index: 36, text: '6:00 P.M', value: "18:00"},
    {index: 37, text: '6:30 P.M', value: "18:30"},
    {index: 38, text: '7:00 P.M', value: "19:00"},
    {index: 39, text: '7:30 P.M', value: "19:30"},
    {index: 40, text: '8:00 P.M', value: "20:00"},
    {index: 41, text: '8:30 P.M', value: "20:30"},
    {index: 42, text: '9:00 P.M', value: "21:00"},
    {index: 43, text: '9:30 P.M', value: "21:30"},
    {index: 44, text: '10:00 P.M', value: "22:00"},
    {index: 45, text: '10:30 P.M', value: "22:30"},
    {index: 46, text: '11:00 P.M', value: "23:00"},
    {index: 47, text: '11:30 P.M', value: "23:30"},
];
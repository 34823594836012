export default {
    set(key, value) {
        localStorage.setItem(key, value);
    },

    get(key) {
        return localStorage.getItem(key);
    },

    setLang(value) {
        localStorage.setItem('ez_leads_car_details_lang', value);
    },

    getLang() {
        return localStorage.getItem('ez_leads_car_details_lang');
    },

    getCompany() {
        return JSON.parse(localStorage.getItem('ez_leads_car_details_company'));
    },

    setCompany(company) {
        const strCompany = JSON.stringify({
            Name: company.Name,
            Logo: company.CompanySettings.Logo,
            Domain: company.Domain,
            Locations: company.Locations,
        });
        localStorage.setItem('ez_leads_car_details_company', strCompany);
    },
};
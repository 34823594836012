import Vue from 'vue';
import Router from 'vue-router';

import CarDetails from "@/components/CarDetails/index.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: 'CarDetails',
            component: CarDetails,
            path: '/:vin'
        },
    ]
});

export default router;
<template>
  <div id="app">
    <CarDetails/>
  </div>
</template>

<script>
import CarDetails from './components/CarDetails/index.vue';

export default {
  name: 'app',
  components: {
    CarDetails
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>